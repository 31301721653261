//Colors
$primaryPurple: #7876F7;
$primaryAqua: #99E6FC;
$primaryBlue: #4466F4;
$secondaryPurple: #a7a6fa;
$secondaryBlue: #295CF1;
$secondaryAqua: #57C7F9;
$lightGrey: #F7F9FC;

//Fonts
$primaryFont: HelveticaNow;

//Screen sizes
$screenLarge: 1400px;
$screenMedium: 1024px;
$screenSmall: 760px;
$screenMobile: 540px;

//Dimensions
$pageMaxWidth: $screenLarge;