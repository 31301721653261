@import "src/variables.scss";

.landing-page-content {
    .register {
        background: rgb(2, 0, 36);
        //background: radial-gradient(circle, rgb(2, 0, 36) 20%, #38369c 100%);
        background: radial-gradient(circle, #3c3ab8 20%, #57C7F9 100%);
        color: #ffffff;

        .content-wrapper {
            padding-top: 100px;
            padding-bottom: 120px;

            display: flex;
            align-items: center;
            flex-direction: column;

            h4 {
                margin-bottom: 100px;
            }

            .section-row {
                flex-direction: row;

                .section-column {
                    justify-content: flex-start;
                    min-width: 400px;
                }
            }

            .register-form {
                display: flex;
                width: 100%;

                .register-email {
                    flex: 1;
                    margin-right: 10px;
                    padding: 5px 12px;
                    min-height: 42px;
                    font-size: 14px;
                    font-family: HelveticaNow;
                }
            }

            @media (max-width: $screenMobile) {
                .register-form {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .register-email {
                        margin-bottom: 20px;
                        margin-right: 0;
                        width: 80%;
                    }

                    .sign-up {
                        width: 80%;
                        justify-content: center;
                    }
                }
            }

            .info {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                p {
                    margin-top: 20px;
                    text-align: left;
                }
            }

        }

    }
}